<template>
  <div>
    <v-card elevation="10">
      <!-- <v-card-title class="card-header justify-content-between"> -->
        <!-- <h3 class="mb-0">Filtros</h3> -->
        <!-- <v-btn color="info" @click="exportRelatorio()" elevation="11"
          >relatorio</v-btn
        > -->
      <!-- </v-card-title> -->

      <!-- <div class="row form-group p-3">
        <div class="col-md-4">
          <label for="data_inicial">Data Incial:*</label>
          <input
            type="date"
            class="form-control"
            v-model="form.data_inicial"
            placeholder="Digite a data inicial"
          />
        </div>

        <div class="col-md-4">
          <label for="data_final">Data Incial:*</label>
          <input
            type="date"
            class="form-control"
            v-model="form.data_final"
            placeholder="Digite a data final"
          />
        </div>

        <div class="col-md-4">
          <label for="tipo">Funcionario:*</label>

          <treeselect
                  
                  :options="lista_funcionarios"
                  placeholder="Selecione  usuario..."
                  v-model="roleForm.roles"
                />
        </div>
      </div> -->
    </v-card>
    <div class="card full-height bg-ligth">
      <div class="card-header justify-content-between d-flex">
        <h3 class="mb-0">Dashbord Relatorios</h3>
      </div>
      <div class="card-body">
        <b-table
        
          class="
            table
            table-head-custom
            table-vertical-center
            table-head-bg
            table-borderless
          "
          show-empty
          empty-text="Em Breve"
          :fields="[
            'id',
            'usuario',
            'total_paginas',
            'total_arquivos',
            'actions',
          ]"
          :per-page="perPage"
          :current-page="currentPage"
          :items="lista_arquivex"
          id="funcionarios-table"
        >

        <template #cell(usuario)="{item}"
      >  {{ item.usuario.nome_sobrenome }}
      </template>
   
    
        </b-table>
        <b-pagination
          v-model="currentPage"
          :total-rows="lista_arquivex.length"
          :per-page="perPage"
          aria-controls="funcionarios-table"
        >
        </b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";
import { fireAlert } from "@/core/mixins/alertMessageMixin";

export default {
  mixins: [fireAlert],
  data() {
    return {
      form:{
        data_inicial: null,
        data_final :null,
        usuario_id : null
      },
      modal_create_regra: false,
      selectedUser: null,
      disableSaveModal: false,
      roleForm: {
        user_id: null,
        roles: [],
      },
      pesquisa: {
        tipo: "nome",
        texto: "",
      },
      currentPage: 1,
      perPage: 5,
      value: "",
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Funcionarios acoes" }]);
  },
  created() {
     this.$store.dispatch("relg/listar_relatorio_arquivex", this.$store.state.sistema.id_filial_selecionada);
  },
  computed: {

    

    lista_arquivex(){
      return this.$store.state.relg.lista_arquivex
    },
    lista_funcionarios(){
      return this.$store.state.configUsuarios.lista_usuarios.map((role) => ({
        id: role.id,
        label: role.name,
      }));
    },
    lista_permissoes_filial() {
      return this.$store.state.sistema.lista_permissoes_filial;
    },
    // lista_roles() {
    //   return this.$store.state.permissoes.lista_roles.map((role) => ({
    //     id: role.id,
    //     label: role.name,
    //   }));
    // },
    lista_usuarios() {
      return this.$store.state.configUsuarios.lista_usuarios.filter((item) => {
        if (!this.pesquisa.texto) return true;
        return (
          String(item[this.pesquisa.tipo])
            .toLowerCase()
            .indexOf(this.pesquisa.texto.toLowerCase()) !== -1
        );
      });
    },
    mensagem_alert() {
      return this.$store.state.configEmpresa.mensagem_alert;
    },
  },
  methods: {
    gerar() {},
    async submitPermissions(e) {
      e.preventDefault();
      this.disableSaveModal = true;
      this.$store
        .dispatch("permissoes/vincular_regra_usuario", this.roleForm)
        .then(() => {
          this.roleForm.roles = [];
          this.$bvModal.hide("modal-roles");
          this.fireAlert({ title: "Atualizado com sucesso" });
        })
        .catch(() => this.fireAlert(this.mensagem_alert))
        .finally(() => (this.disableSaveModal = false));

      this.modal_create_regra = false;
    },

    atualizar(value) {
      this.$router.push({ name: "createFuncionario" });
      this.$store.dispatch("configEmpresa/atualizar", value);
    },
    async pesquisar() {
        await this.$store.dispatch(
        "configUsuarios/pesquisar_usuarios",
        this.pesquisa
      );
      },
    async trocar_pagina(name, id) {
      await this.$store.dispatch("configEmpresa/guardarDados", id);
      this.$router.push({ name: name });
    },

    financeiro(name, id) {
      this.$router.push({ name: name, params: { usuario_id: id } });
    },
  },
};
</script>

<style scoped>
.lista:hover {
  background-color: rgba(167, 183, 255, 0.705);
}
</style>