var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"elevation":"10"}}),_c('div',{staticClass:"card full-height bg-ligth"},[_vm._m(0),_c('div',{staticClass:"card-body"},[_c('b-table',{staticClass:"\n          table\n          table-head-custom\n          table-vertical-center\n          table-head-bg\n          table-borderless\n        ",attrs:{"show-empty":"","empty-text":"Em Breve","fields":[
          'id',
          'usuario',
          'total_paginas',
          'total_arquivos',
          'actions' ],"per-page":_vm.perPage,"current-page":_vm.currentPage,"items":_vm.lista_arquivex,"id":"funcionarios-table"},scopedSlots:_vm._u([{key:"cell(usuario)",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.usuario.nome_sobrenome)+" ")]}}])}),_c('b-pagination',{attrs:{"total-rows":_vm.lista_arquivex.length,"per-page":_vm.perPage,"aria-controls":"funcionarios-table"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-header justify-content-between d-flex"},[_c('h3',{staticClass:"mb-0"},[_vm._v("Dashbord Relatorios")])])}]

export { render, staticRenderFns }